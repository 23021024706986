html {
  scroll-behavior: smooth;
}

body {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button:focus {
  outline: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('./assets/fonts/SFProDisplay-Medium.ttf'), url('./assets/fonts/SFProDisplay-Light.ttf');
}

@font-face {
  font-family: 'Gelion';
  src: 
    /*url('./assets/fonts/Gelion-Medium.ttf'),*/ url('./assets/fonts/Gelion-Regular.ttf'),
    url('./assets/fonts/Gelion-Light.ttf');
}

@font-face {
  font-family: 'Work Sans';
  src: url('./assets/fonts/WorkSans-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Work Sans';
  src: url('./assets/fonts/WorkSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Work Sans';
  src: url('./assets/fonts/WorkSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Work Sans';
  src: url('./assets/fonts/WorkSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Work Sans';
  src: url('./assets/fonts/WorkSans-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-SemiBolditalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

.grecaptcha-badge {
  visibility: hidden;
}
